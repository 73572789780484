import React from 'react';
import { useEffect, useState } from 'react';
import './App.css';

function App() {
  const [date, setDate] = useState(null);
  useEffect(() => {
    async function getDate() {
      const res = await fetch('/api/date');
      const newDate = await res.text();
      setDate(newDate);
    }
    getDate();
  }, []);
  return (
    <main>
    <iframe src="https://geektyper.com/scp/" title="aa" width="100%" height="100%"  border="0" style={{border:0}}></iframe>
    </main>
  ); 
}

export default App;
